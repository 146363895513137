











import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import Page from '@/components/poultry/layout/Page.vue';
import AnalysisTab from './AnalysisTab.vue';
import AnalysisDropdowns from './AnalysisDropdowns.vue';

@Component({
  components: {
    Page,
    AnalysisDropdowns
  }
})
export default class Analysis extends Vue {
  created() {
    document.title = 'Elanco | Analysis';
  }
}
