

































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { DropdownRoutes } from '@/models/poultry/dropdown.model';
import { reportTypeStores } from '@/store/poultry/reportType';
import { ReportTypeIds } from '@/constants';

@Component({})
export default class DropdownAnalysisRoutes extends Vue {
    @Prop() disabled!: boolean;
    @Prop() filterType!: string;
    @Prop() isResetSelected!: boolean;
    @Prop() resetTitle!: string;
    @Prop() dropdownTitle!: string;
    @Prop({
        type: Array,
        default: [],
    })
    private options!: DropdownRoutes[];
    @Prop()
    private selectedOption!: DropdownRoutes | string;
    private availableRoutes: DropdownRoutes[] = [];

    reportTypeDetailStore = reportTypeStores.detail;

    isActive = false;
    selected: DropdownRoutes = {
        text: '',
        name: '',
        reportTypeId: 0
    };
    keywords: string = '';

    @Emit('select')
    @Watch('selected')
    handleSelected(data: DropdownRoutes) {
        this.selected = data;
        return data;
    }

    @Emit('select')
    handleResetSelected() {
        this.selected = {
            text: '',
            name: '',
            reportTypeId: 0
        };
        return this.selected;
    }

    created() {
        this.initializeDefaultOption();
    }

    get isWaterReport(){
        return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.water;
    }

    get isInjectableReport(){
        return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.injectable;
    }

    get isHatcheryReport(){
        return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.hatchery;
    }

    get isCndReport(){
        return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.cnd;
    }

    get isIpmReport(){
        return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.ipm;
    }

    get isLowReport(){
        return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.labOnWheel;
    }

    get isEbaReport(){
        return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.eba;
    }

    get isPeaReport(){
        return String(this.reportTypeDetailStore.reportTypeId) === ReportTypeIds.pea;
    }

    changeDropdownColor(){
        let dropdownColor = '';

        switch(String(this.reportTypeDetailStore.reportTypeId)){
            case ReportTypeIds.water:
                dropdownColor = 'water';
                break;
            case ReportTypeIds.injectable:
                dropdownColor = 'injectable';
                break;
            case ReportTypeIds.hatchery:
                dropdownColor = 'hatchery';
                break;
            case ReportTypeIds.cnd:
                dropdownColor = 'cleaning';
                break;
            case ReportTypeIds.ipm:
                dropdownColor = 'ipm';
                break;
            case ReportTypeIds.labOnWheel:
                dropdownColor = 'low';
                break;
            case ReportTypeIds.eba:
                dropdownColor = 'eba';
                break;
            case ReportTypeIds.pea:
                dropdownColor = 'pea';
                break;
            default:
                dropdownColor = '';
                break;
        }

        return `is-active-${dropdownColor}`;
    }

    mounted() {
        document.addEventListener('click', this.closeDropdown);
    }

    destroyed() {
        document.removeEventListener('click', this.closeDropdown);
    }

    closeDropdown(e: any) {
        if (!this.$el.contains(e.target)) {
            this.isActive = false;
        }
    }

    @Emit('onSearch')
    handleFormSubmitted() {
        return this.keywords;
    }

    initializeDefaultOption() {
        if (this.selectedOption && this.selectedOption.constructor === Object) {
            this.selected = this.selectedOption as DropdownRoutes;
        } else {
            this.options.map(option => {
                if (option.reportTypeId === this.reportTypeDetailStore.reportTypeId) {
                    this.availableRoutes.push(option);
                }
            });
            const option = this.options.find((el) => {
                el === this.selectedOption;
            });

            if (option) {
                this.selected = option;
            } else {
                this.selected = this.options.find((el) => el.reportTypeId === this.reportTypeDetailStore.reportTypeId) || this.options[0]
                if (this.selected.name === 'analysis.visual')
                {
                    this.$router.push({
                        name: this.selected.name,
                    })
                }
            }
        }
    }
}
