











import { Component, Vue, Ref } from 'vue-property-decorator';
import DropdownAnalysisRoutes from '@/components/poultry/DropdownAnalysisRoutes.vue';
import { reportTypeStores } from '@/store/poultry/reportType';
import { ReportTypeIds } from '@/constants';
@Component({
    components: {
        DropdownAnalysisRoutes
    }
})
export default class AnalysisTypeFilter extends Vue {
    reportTypeDetailStore = reportTypeStores.detail;
    filteredDropdownRoutes = [];
    dropdownRoutes = [
        { id: 1, reportTypeId: ReportTypeIds.water, text: 'Results Analysis', name: 'analysis.results' },
        { id: 2, reportTypeId: ReportTypeIds.water, text: 'Preparation Analysis', name: 'analysis.preparation' },
        { id: 3, reportTypeId: ReportTypeIds.water, text: 'Vaccination Analysis', name: 'analysis.vaccination' },
        { id: 4, reportTypeId: ReportTypeIds.injectable, text: 'Results Analysis', name: 'analysis.results' },
        { id: 5, reportTypeId: ReportTypeIds.injectable, text: 'Preparation Analysis', name: 'analysis.preparation' },
        { id: 6, reportTypeId: ReportTypeIds.injectable, text: 'Vaccination Analysis', name: 'analysis.vaccination' },
        { id: 7, reportTypeId: ReportTypeIds.hatchery, text: 'Results Analysis', name: 'analysis.results' },
        { id: 8, reportTypeId: ReportTypeIds.hatchery, text: 'Hatchery Analysis', name: 'analysis.hatchery' },
        { id: 9, reportTypeId: ReportTypeIds.cnd, text: 'Visual Assessment', name: 'analysis.visual' },
        { id: 10, reportTypeId: ReportTypeIds.cnd, text: 'Swab Samples Collection', name: 'analysis.swab_samples' },
        { id: 11, reportTypeId: ReportTypeIds.ipm, text: 'Results Analysis', name: 'analysis.results' },
        { id: 12, reportTypeId: ReportTypeIds.labOnWheel, text: 'Results Analysis', name: 'analysis.results' },
        { id: 13, reportTypeId: ReportTypeIds.eba, text: 'Results Analysis', name: 'analysis.results' },
        { id: 14, reportTypeId: ReportTypeIds.pea, text: 'Results Analysis', name: 'analysis.results' }
    ];

    @Ref() activeSelectedDropdown!: any;
}
