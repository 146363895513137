


























































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { reportTypeStores } from '@/store/poultry/reportType';
import Dropdown from './Dropdown.vue';
import { ReportTypeIds } from '@/constants';

@Component({})
export default class DropdownAnalysis extends Vue {
    @Prop() disabled!: boolean;
    @Prop() filterType!: string;
    @Prop() isResetSelected!: boolean;
    @Prop() resetTitle!: string;
    @Prop() dropdownTitle!: string;
    @Prop({
        type: Array,
        default: [],
    })
    private options!: DropdownOption[];
    @Prop()
    private selectedOption!: DropdownOption | string;

    reportTypeDetailStore = reportTypeStores.detail;


    isActive = false;
    selected: DropdownOption = {
        text: '',
        value: '',
    };
    keywords: string = '';

    @Emit('select')
    @Watch('selected')
    handleSelected(data: DropdownOption) {
        this.selected = data;
        this.reportTypeDetailStore.updateReportTypeId(data.value);
        this.reportTypeDetailStore.fetchById();
        return data;
    }

    get isWaterReport(){
        return this.selected.value === ReportTypeIds.water;
    }

    get isInjectableReport(){
        return this.selected.value === ReportTypeIds.injectable;
    }

    get isHatcheryReport(){
        return this.selected.value === ReportTypeIds.hatchery;
    }

    get isCndReport(){
        return this.selected.value === ReportTypeIds.cnd;
    }

    get isIpmReport(){
        return this.selected.value === ReportTypeIds.ipm;
    }

    get isLowReport(){
        return this.selected.value === ReportTypeIds.labOnWheel;
    }

    get isEbaReport(){
        return this.selected.value === ReportTypeIds.eba;
    }

    get isPeaReport(){
        return this.selected.value === ReportTypeIds.pea;
    }

    changeDropdownColor(){
        let dropdownColor = '';

        switch(String(this.reportTypeDetailStore.reportTypeId)){
        case ReportTypeIds.water:
            dropdownColor = 'water';
            break;
        case ReportTypeIds.injectable:
            dropdownColor = 'injectable';
            break;
        case ReportTypeIds.hatchery:
            dropdownColor = 'hatchery';
            break;
        case ReportTypeIds.cnd:
            dropdownColor = 'cleaning';
            break;
        case ReportTypeIds.ipm:
            dropdownColor = 'ipm';
            break;
        case ReportTypeIds.labOnWheel:
            dropdownColor = 'low';
            break;
        case ReportTypeIds.eba:
            dropdownColor = 'eba';
            break;
        case ReportTypeIds.pea:
            dropdownColor = 'pea';
            break;
        default:
            dropdownColor = '';
            break;
        }

        return `is-active-${dropdownColor}`;
    }

    @Emit('select')
    handleResetSelected() {
        this.selected = {
            text: '',
            value: '',
        };
        return this.selected;
    }

    created() {
        this.initializeDefaultOption();
    }

    mounted() {
        document.addEventListener('click', this.closeDropdown);
    }

    destroyed() {
        document.removeEventListener('click', this.closeDropdown);
    }

    closeDropdown(e: any) {
        if (!this.$el.contains(e.target)) {
            this.isActive = false;
        }
    }

    @Emit('onSearch')
    handleFormSubmitted() {
        return this.keywords;
    }

    initializeDefaultOption() {
        if (this.selectedOption && this.selectedOption.constructor === Object) {
            this.selected = this.selectedOption as DropdownOption;
            this.reportTypeDetailStore.updateReportTypeId(this.selected.value);
        } else {
            const option = this.options.find((el) => {
                el.value === this.selectedOption;
            });
            
            if (option) {
                this.selected = option;
                this.reportTypeDetailStore.updateReportTypeId(this.selected.value);
            } else {
                this.selected = this.options[0];
                this.reportTypeDetailStore.updateReportTypeId(this.selected.value);
            }
        }
    }
}
