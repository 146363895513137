













import { Component, Vue, Watch } from 'vue-property-decorator';
import ReportTypeFilter from './AnalysisFilter/ReportTypeFilter.vue';
import AnalysisTypeFilter from './AnalysisFilter/AnalysisTypeFilter.vue';
import { reportTypeStores } from '@/store/poultry/reportType';

@Component({
    components: {
        ReportTypeFilter,
        AnalysisTypeFilter
    }
})
export default class AnalysisDropdowns extends Vue {
    reportTypeDetailStore = reportTypeStores.detail;

    @Watch('reportTypeDetailStore.reportTypeId')
    refreshComponent() {
        if (String(this.reportTypeDetailStore.reportTypeId) !== '4')
            this.$router.push('/poultry/analysis', () => {});
    }
}
