

















import { Component, Vue, Ref } from 'vue-property-decorator';
import DropdownAnalysis from '@/components/poultry/DropdownAnalysis.vue';
import { reportTypeStores } from '@/store/poultry/reportType';
import { reportStores } from '@/store/poultry/report';
import { DropdownOption } from '@/models/poultry/dropdown.model';
import { ReportTypeNames, ReportNames } from '@/constants';

@Component({
    components: {
        DropdownAnalysis
    }
})
export default class ReportTypeFilter extends Vue {
    reportTypeListStore = reportTypeStores.list;
    reportListStore = reportStores.list;

    reportTypeOptions: DropdownOption[] = [];

    @Ref() activeSelectedDropdown!: any;

    get selectedReportTypeId() {
        return this.reportListStore.apiParams.reportTypeIds;
    }

    async created() {
        await this.reportTypeListStore.fetchAll();
        this.getReportTypeOptions();
    }

    handleSelectedReportTypeId(params: DropdownOption) {
        this.reportListStore.updatePagination(this.reportListStore.defaultPagination);

        this.reportListStore.fetchAll({
            clientId: params.value,
            farmIds: [],
            reportTypeIds: [],
            auditorIds: [],
            months: [],
            years: [],
        })
    }

    getReportTypeOptions(){
        let optionText = '';

        this.reportTypeListStore.reportTypes.forEach(reportType => {

            switch(reportType.name){
                case ReportTypeNames.water:
                    optionText = ReportNames.water;
                    break;
                case ReportTypeNames.injectable:
                    optionText = ReportNames.injectable;
                    break;
                case ReportTypeNames.hatchery:
                    optionText = ReportNames.hatchery;
                    break;
                case ReportTypeNames.cnd:
                    optionText = ReportNames.cnd;
                    break;
                case ReportTypeNames.ipm:
                    optionText = ReportNames.ipm;
                    break;
                case ReportTypeNames.labOnWheel:
                    optionText = ReportNames.labOnWheel;
                    break;
                case ReportTypeNames.pea:
                    optionText = ReportNames.pea;
                    break;
                default: 
                    optionText = ReportNames.eba;
                    break;
            }

            this.reportTypeOptions.push(
                {
                    text: optionText,
                    value: reportType.id.toString(),
                }
            );
        });
    }
}
